import React from 'react'


export default class Box extends React.Component {
  render() {
    return (
        <div className={this.props.boxtype} >
          <div className="box-header with-border">
            <h3 className="box-title"> {this.props.title}</h3>
            <div className="box-tools pull-right">
              <button type="button" className="btn btn-box-tool" data-widget="collapse" data-toggle="tooltip" title="Collapse">
                <i className="fa fa-minus" />
              </button>
              <button type="button" className="btn btn-box-tool" data-widget="remove" data-toggle="tooltip" title="Remove">
                <i className="fa fa-times" />
              </button>
            </div>
          </div>
          <div className="box-body">
            {this.props.children}
          </div>
        </div>
    )
  }
}