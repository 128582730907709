import React from 'react';
import * as THREE from 'three'
import { TrackballControls } from 'three-trackballcontrols-ts';
import ReactDOM from "react-dom";

export default class Scene extends React.Component {

  constructor(props) {
    super()
    this.props = props
    this.start = this.start.bind(this)
    this.stop = this.stop.bind(this)
    this.animate = this.animate.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.onClick = this.onClick.bind(this)
    this.selectStones = this.selectStones.bind(this)
    this.deselectStones = this.deselectStones.bind(this)
    this.selStones = []

  }


  componentDidUpdate(prevProps) {
    var searchstr = '?'
    if (prevProps.Search !== this.props.Search) {
      if (this.selStones.length > 0) {
        this.selStones.map(item => (
          this.deselectStones(item)
        ))
      }
      for (var prop in this.props.Search) {
        if (this.props.Search[prop].value !== 'null' && this.props.Search[prop].value != '') {
          searchstr += prop + '=' + this.props.Search[prop].value + '&'
        }
      }
      searchstr = searchstr.substring(0, searchstr.length - 1);
      if (searchstr) {
        fetch('https://domsteine.api.paulsoft.net/api/findrestobj' + searchstr)
          .then(res => res.json())
          .then(data => {
              this.props.stoneFindHandlerFromParant(data.length)
              console.log(data.length)
              data.map(item => (
               this.selectStones(item)
            ))
          })
      }
    }
  }

  deselectStones(item) {
    if (this.scene.getObjectByName(item)) {
      this.scene.getObjectByName(item).material = this.material
    }
  }

  selectStones(item) {
    this.selStones.push(item)
    if (this.scene.getObjectByName(item)) {
      console.log(this.scene.getObjectByName(item))
      this.scene.getObjectByName(item).material = this.select_material
    }
  }

  handleResize() {

    let height = ReactDOM.findDOMNode(this).parentNode.getBoundingClientRect().height
    let width = ReactDOM.findDOMNode(this).parentNode.getBoundingClientRect().width

    //console.log(ReactDOM.findDOMNode(this).getBoundingClientRect())

    // let height = document.getElementById('#SceneBox').innerHeight
    // let width  = document.getElementById('#SceneBox').innerWidth

    this.camera.aspect = width / height
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(width - 20, height - 30)
  }

  
  onClick(event) {
    event.preventDefault()
    var mouse3D = new THREE.Vector3()
    let canvasBounds = event.target.getBoundingClientRect()
    mouse3D.x = ((event.clientX - canvasBounds.left) / (canvasBounds.right - canvasBounds.left)) * 2 - 1
    mouse3D.y = - ((event.clientY - canvasBounds.top) / (canvasBounds.bottom - canvasBounds.top)) * 2 + 1
    var raycaster = new THREE.Raycaster()
    raycaster.setFromCamera(mouse3D, this.camera)
    var intersects = raycaster.intersectObjects(this.scene.children, true)
    if (intersects.length > 0) {
      let sobj = []
      if (sobj = this.scene.getObjectByName("selected")) {
        sobj.material = this.material
        sobj.name = intersects[0].object.userData.StoneId
      }
      intersects[0].object.material = this.pick_material
      this.props.stoneIdHandlerFromParant(intersects[0].object.userData.StoneId);
      intersects[0].object.name = 'selected'
    }

  }

  componentDidMount() {

    const width = ReactDOM.findDOMNode(this).parentNode.getBoundingClientRect().height
    const height = ReactDOM.findDOMNode(this).parentNode.getBoundingClientRect().height

    this.ref = this.refs.Container
    window.addEventListener('resize', this.handleResize, false)

    //ADD SCENE
    this.scene = new THREE.Scene()
    this.scene.background = new THREE.Color(0xd6d4ce);

    //ADD CAMERA
    this.camera = new THREE.PerspectiveCamera(
      75,
      width / height,
      0.1,
      1000
    )
    //this.camera.position.z = 4    

    //ADD RENDERER
    this.renderer = new THREE.WebGLRenderer({ antialias: true })
    this.renderer.setClearColor(0xe3e3e3, 1);
    this.renderer.setSize(width, height)
    this.mount.appendChild(this.renderer.domElement)

    //ADD CUBE
    this.geometry = new THREE.BoxGeometry(0.3, 0.3, 0.3)
    this.material = new THREE.MeshLambertMaterial({ color: 0xF3E2A9, opacity: 0.9, transparent: true });
    this.select_material = new THREE.MeshLambertMaterial({ color: 0xFF0000, opacity: 0.9, transparent: true });
    this.pick_material = new THREE.MeshLambertMaterial({ color: 0x00c0ef, opacity: 0.9, transparent: true });



    this.dLight = new THREE.DirectionalLight(0xffffff);
    this.dLight.position.set(906, 226, 1000);
    this.dLight.shadowCameraVisible = true;
    this.dLight.shadowCameraNear = 1;
    this.dLight.shadowCameraFar = 1500;
    this.dLight.castshadow = true;
    this.scene.add(this.dLight);


    this.controls = new TrackballControls(this.camera, this.renderer.domElement);
    this.controls.dynamicDampingFactor = 0.5;
    
    this.controls.target.set(980, 111, 985);

    //this.controls.target.set(988, 102, 978);

    this.camera.position.x = 972.3412;
    this.camera.position.z = 1079.8083;
    this.camera.position.y = 119.7848;


    fetch('https://domsteine.api.paulsoft.net/api/restobjsmall')
      .then(res => res.json())
      .then(data => {
        Object.keys(data).map((item, i) => (
          this.addCube(data[item].koord.wo, data[item].koord.z, data[item].koord.ns, this.geometry, this.material, data[item].id)
        ))
      })

    this.start()

  }


  addCube(x, y, z, geometry, material, StoneId) {
    const cube = new THREE.Mesh(geometry, material);
    cube.userData = { StoneId: StoneId };
    cube.name = StoneId;
    this.scene.add(cube);
    cube.position.set(x, y, z);
  }


  componentWillUnmount() {
    this.stop()
    this.mount.removeChild(this.renderer.domElement)
  }

  start() {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate)
    }
  }

  stop() {
    cancelAnimationFrame(this.frameId)
  }

  animate() {

    this.controls.update()
    this.renderScene()
    this.frameId = window.requestAnimationFrame(this.animate)
    this.handleResize()
  }

  renderScene() {
    this.renderer.render(this.scene, this.camera)


  }

  render() {
    return (
      <div
        style={{ width: '30vw', height: '80vh' }}
        onClick={this.onClick}
        ref={ref => (this.mount = ref)} />
    )
  }
}