import React, { Component} from 'react'

export default class Footer extends Component {
    render() {
        return (
            <div className="main-footer">
                    <div className="pull-right hidden-xs">
                    <b>Version</b> 2.4.0
                    </div>
                    <strong>Copyright © 2019 <a href="http://www.paulsoft.de">PaulSoft</a>.</strong> All rights
                    reserved.
            
            </div>
        )
    }
}