import React from 'react'
import Header from '../components/Header'
import Menu from '../components/Menu'
import Box from '../components/box'
import Footer from '../components/Footer'
import Scene from '../components/Scene'


export class Dashboard extends React.Component {

  constructor() {
    super()
    this.handleStoneSelect = this.handleStoneSelect.bind(this)
    this.cleanObj = this.cleanObj.bind(this)
    this.zeroPad = this.zeroPad.bind(this)
    this.badge = this.badge.bind(this)
    this.stoneFindCountHandler = this.stoneFindCountHandler.bind(this)
    this.state = {
      id: '',

      koord: [],
      stone: [],
      masznahme: [],
      file: [],
      data: [],
      formControls: {
        steinmetz: {
          value: ''
        },
        inschrift: {
          value: ''
        },
        hakenloch: {
          value: ''
        },
        fremdkorper: {
          value: ''
        }, 
        stonetypID: {
          value: ''
        }               
      }

    }
    this.ImgPath = ''
    this.Schaden = []

  }


  handleErrors(response) {
    if (!response.ok) {
        alert(response.statusText);
    }
    return response;
  }

  componentDidMount() {
    fetch('https://domsteine.api.paulsoft.net/api/sets_stonetyps')
      .then(this.handleErrors)
      .then(res => res.json())
      .then(json => this.setState({ data: json }));

  }

  searchChangeHandler(event) {

    event.preventDefault()

    const name = event.target.name
    const value = event.target.value
  
    this.setState({
      formControls: {
          ...this.state.formControls,
          [name]: {
          ...this.state.formControls[name],
          value
        }
      },
      stoneFind: 
        ''
    });
  }

  stoneFindCountHandler(data) {
    this.setState({stoneFind: 
      <div className="alert alert-danger alert-dismissible">
      <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
      <i className="icon fa fa-info" /> Die Suche ergab eine Anzahl von {data} Steinen
      </div>      
    })
  }  

  handleStoneSelect(stoneId) {
    fetch('https://domsteine.api.paulsoft.net/api/restobj/' + stoneId)
      .then(this.handleErrors)
      .then(res => res.json())
      .then(data => {
        this.setState({
          id: data.id,
          koord: data.koord,
          stone: data.stone,
          BaulteiKatName: data.stone.stoneStoneBauteilKat.bauTeilKatName,
          StoneTypName: data.stone.stoneTyp.stoneTypName,
          StoneBearbeitSpurName: data.stone.stoneBearbeitSpur.bearbeitsSpurName,
          masznahme: data.masznahme,
          file: data.file,
          StoneSonstMerkmale: '',
        })

        this.setState({ StoneSonstMerkmale: this.state.stone.stoneSteinMetz ? 'Steinmetzzeichen ' : '' })
        this.setState({ StoneSonstMerkmale: this.state.StoneSonstMerkmale + (this.state.stone.stoneHakenLoch ? 'Hakenloch ' : '') })
        this.setState({ StoneSonstMerkmale: this.state.StoneSonstMerkmale + (this.state.stone.stoneInschrift ? 'Inschrift ' : '') })
        this.setState({ StoneSonstMerkmale: this.state.StoneSonstMerkmale + (this.state.stone.stoneFremdKorper ? 'Fremdkörper ' : '') })


        this.SchadenName = {
          hohlkehlen: "Zurundung/Hohlkehlen",
          schichtpara_auswitt: "schichtparalleles Auswittern",
          alveolarverwitt: "Alveolarverwitterung",
          auswittKomponenten: "Auswittern von Komponenten",
          rueckverwittSchalenver: "Rückverwitterung durch Schalenverlust",
          rueckverwittMaterial: "Rückverwitterung durch anderen Materialverlust",
          ausbruch: "Ausbruch",
          schichtunabhaeRisse: "schichtunabhängige Risse",
          schichtparallel: "schichtparallele Risse",
          verfaerbung: "Verfärbung",
          verschmutzung: "Verschmutzung",
          kruste: "Kruste",
          krusteAbloesend: "Kruste, ablösend",
          salzbluehung: "Salzausblühung",
          biogenerAufwuchs: "Biogener Aufwuchs",
          absanden: "Absanden",
          schuppen: "Schuppen",
          duenneSchale: "dünnste Schale",
          schaleEinf: "einfache Schale",
          schaleMehrf: "mehrfache Schale",
          abbroeckeln: "Abbröckeln",
          aufblaettern: "Aufblättern",
          absandenSchuppen: "Absanden bis Schuppen",
          schuppenSchale: "Schuppen bis Schalen",
          schaleAbbroeckeln: "Schale bis Abbröckeln",
          schuppenAbbroeckeln: "Schuppen bis Abbröckeln",
          absandenAbbroeckeln: "Absanden bis Abbröckeln"
        }

        delete this.state.stone.stoneSchaden['id']
        this.cleanObj(this.state.stone.stoneSchaden)
        this.StoneSchadenTab = Object.keys(this.state.stone.stoneSchaden).map(key =>
          <tr>
            <td>{this.SchadenName[key]}</td>
            <td>{this.badge(this.state.stone.stoneSchaden[key])}</td>
          </tr>
        )

        this.BauMaszName = {
          bauetappe: "Bauetappe",
          historisch: "historische Maßnahmen",
          juengere: "jüngere Maßnahmen",
          geplant: "geplante Maßnahmen",
          kommentar: "Kommentar"
        }
        delete this.state.masznahme['id']
        this.masznahmeTab = this.state.masznahme && Object.keys(this.state.masznahme).map(key =>
          <tr>
            <td>{this.BauMaszName[key]}</td>
            <td>{this.state.masznahme[key]}</td>
          </tr>
        )

        this.forceUpdate();
      })
  }

  badge(lvl) {
    switch (lvl) {
      case 1: return <span class="badge bg-green">schwach</span>
      case 2: return <span class="badge bg-yellow">mittel</span>
      case 3: return <span class="badge bg-red">stark</span>
      default: return "";
    }
  }

  zeroPad(num, places) {
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
  }

  cleanObj(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  }

  render() {
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="content">
            <div className="row">
              <div className="col-md-4">
                <Box title={'Suche'} boxtype={'box box-warning'}>
                  <form class="form-horizontal">
                  <div className="form-group">
                    <label className="col-sm-3 control-label">Steinmetzzeichen:</label>
                    <div className="col-sm-9">
                    <select className="form-control" value={this.state.formControls.steinmetz.value}  name="steinmetz" onChange={(e) => this.searchChangeHandler(e)}>
                      <option value={'null'}>--</option>
                      <option value={'true'}>vorhanden</option>
                      <option value={'false'}>nicht vohanden</option>
                    </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-3 control-label">Inschrift:</label>
                    <div className="col-sm-9">
                    <select className="form-control" value={this.state.formControls.inschrift.value} name="inschrift" onChange={(e) => this.searchChangeHandler(e)}>
                      <option value={'null'}>--</option>
                      <option value={'true'}>vorhanden</option>
                      <option value={'false'}>nicht vohanden</option>
                    </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-3 control-label">Hakenloch:</label>
                    <div className="col-sm-9">
                    <select className="form-control" value={this.state.formControls.hakenloch.value} name="hakenloch" onChange={(e) => this.searchChangeHandler(e)}>
                      <option value={'null'}>--</option>
                      <option value={'true'}>vorhanden</option>
                      <option value={'false'}>nicht vohanden</option>
                    </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-3 control-label">Fremdkörper:</label>
                    <div className="col-sm-9">
                    <select className="form-control" value={this.state.formControls.fremdkorper.value} name="fremdkorper" onChange={(e) => this.searchChangeHandler(e)}>
                      <option value={'null'}>--</option>
                      <option value={'true'}>vorhanden</option>
                      <option value={'false'}>nicht vohanden</option>
                    </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-3 control-label">Steintypen:</label>
                    <div className="col-sm-9">
                    <select className="form-control" value={this.state.formControls.stonetypID.value} name="stonetypID" onChange={(e) => this.searchChangeHandler(e)}>
                      <option value={'null'}>--</option>
                      {this.state.data.map(el => (
                        <option value={el.id}>{el.stoneTypName}</option>
                      ))}
                    </select>
                    </div>
                  </div>
                  {this.state.stoneFind}
                  </form>
                </Box>
                <Box title={'Steindaten'} boxtype={'box box-warning'}>
                  Rest-Id: {this.state.id} Koord-Id: {this.state.koord.id} Stein-Id: {this.state.stone.id} NS: {this.state.koord.ns} WO: {this.state.koord.wo} Z: {this.state.koord.z}<br />
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td><b>Material</b></td>
                        <td>
                          {this.state.StoneTypName}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Bearbeitungsspuren </b></td>
                        <td>
                          {this.state.StoneBearbeitSpurName}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Bauteikategorie</b></td>
                        <td>
                          {this.state.BaulteiKatName}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Sonstige Merkmale</b></td>
                        <td>
                          {this.state.StoneSonstMerkmale}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <ul className="mailbox-attachments clearfix">
                    {
                      Object.keys(this.state.file).map((item, i) => (
                        <li>
                          <span className="mailbox-attachment-icon has-img">
                            <a href={'https://domsteine.img.paulsoft.net/DOM_HBS_' + this.zeroPad(this.state.file[item].fileID, 8) + '.jpg'} target="_blank" alt="linker Mausklick für Vollansicht">
                              <img src={'https://domsteine.img.paulsoft.net/tn200/tn_DOM_HBS_' + this.zeroPad(this.state.file[item].fileID, 8) + '.jpg'} /> </a></span>

                          <div className="mailbox-attachment-info">
                            <span className="mailbox-attachment-size"><i className="fa fa-calendar"></i> {this.state.file[item].EXIF_DateTimeForm}</span>
                            <span className="mailbox-attachment-size"><i className="fa fa-camera"></i> {this.state.file[item].EXIF_Model}</span>
                            <span class="mailbox-attachment-size"><i className="fa fa-file-image-o"></i> {this.state.file[item].fileName}</span>
                            <span className="mailbox-attachment-size"><i className="fa fa-sticky-note-o"></i> {this.state.file[item].bemerkung1}</span>
                            <span className="mailbox-attachment-size">{this.state.file[item].bemerkung2}</span>
                          </div>
                        </li>
                      ))
                    }
                  </ul>
                  {this.state.file.length > 0 ? (
                        <div className="alert alert-info alert-dismissible">
                        <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                        <i className="icon fa fa-info" /> Auf das Bild klicken für Vollansicht.
                        </div>  
                        ) : (
                          <div />
                        )}
                 </Box>
                <Box title={'Schaden'} boxtype={'box box-danger'}>
                  <table className="table table-bordered">
                    <tbody>
                      {this.StoneSchadenTab}
                    </tbody>
                  </table>
                </Box>
                <Box title={'Maßnahme'} boxtype={'box box-success'}>
                  <table className="table table-bordered">
                    <tbody>
                      {this.masznahmeTab}
                    </tbody>
                  </table>
                </Box>
              </div>
              <div className="col-md-8">
                <Box title={'3D Ansicht'} boxtype={'box'}>
                  <Scene stoneIdHandlerFromParant={this.handleStoneSelect}  stoneFindHandlerFromParant={this.stoneFindCountHandler} Search={this.state.formControls}/>
                </Box>
                <div className="alert alert-info alert-dismissible">
                  <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                  <i className="icon fa fa-info" /> Steuerung: linke Maustaste drehen, rechte Maustaste verschieben, Mausrad zoomen.
                  </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />

      </div>

    )
  }
}

