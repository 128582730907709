import React from 'react'
import Header from '../components/Header'
import Menu from '../components/Menu'
import NotFound from '../components/NotFound'
import Footer from '../components/Footer'

export class Error_404 extends React.Component {
  render() {
    return (
          <div id="wrapper">
          <Header/>
          <Menu/>
          <NotFound/>
          <Footer/>
        </div>
      )
  }
}