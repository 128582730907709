import React, { Component} from 'react'

export default class Menu extends Component {
    render() {
        return (
            <div>
                <aside className="main-sidebar">
                        {/* sidebar: style can be found in sidebar.less */}
                        <section className="sidebar">
                        {/* sidebar menu: : style can be found in sidebar.less */}
                        <ul className="sidebar-menu" data-widget="tree">
                            <li><a href="/dashboard"><i className="fa fa-search" /><span>Dashboard</span></a></li>
                        </ul>
                        </section>
                        {/* /.sidebar */}
                    </aside>
            </div>

        )
    }
}