import React, { Component } from 'react'
import { withRouter } from "react-router";


class Header extends Component {

    constructor() {
        super();
        // this.Auth = new AuthService();
        // this.username = this.Auth.getProfile().data.firstname + ' ' + this.Auth.getProfile().data.lastname
        // this.handleLogoutClick = this.handleLogoutClick.bind(this);
    }

    // handleLogoutClick() {
    //     this.Auth.logout()
    //     this.props.history.push('/');
    //   }  


    render() {
        const { match, location, history } = this.props
        return (
            <div>
                <header className="main-header">
                    {/* Logo */}
                    <a href="/" className="logo">
                        {/* mini logo for sidebar mini 50x50 pixels */}
                        <span className="logo-mini"><b>D</b>om</span>
                        {/* logo for regular state and mobile devices */}
                        <span className="logo-lg">
                            <b>DOM</b>
                        </span>
                    </a>
                    {/* Header Navbar: style can be found in header.less */}
                    <nav className="navbar navbar-static-top">
                        {/* Sidebar toggle button*/}
                        <a
                            href="/"
                            className="sidebar-toggle"
                            data-toggle="push-menu"
                            role="button">
                            <span className="sr-only">
                                Toggle navigation
                </span>
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                        </a>
                        <div className="navbar-custom-menu">
                            <ul className="nav navbar-nav">
                                {/* User Account: style can be found in dropdown.less */}
                                <li className="dropdown user user-menu">
                                    <a
                                        href="/"
                                        className="dropdown-toggle"
                                        data-toggle="dropdown">
                                        <span className="hidden-xs">
                                            {/* {this.username} */}
                                        </span>
                                    </a>
                                    <ul className="dropdown-menu">
                                        {/* User image */}
                                        <li className="user-body">
                                            <div className="pull-left">
                                                <a href="/profile" className="btn btn-default btn-flat">Profile</a>
                                            </div>
                                            <div className="pull-right">
                                                <button onClick={this.handleLogoutClick} className="btn btn-default btn-flat">Sign out </button>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </header>

            </div>

        )
    }
}

export default withRouter(Header)