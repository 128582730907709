import React from 'react'

export class Home extends React.Component {


  render() {

    const style = {marginTop: '10%'}
    return (
<div className="jumbotron d-flex align-items-center" style={style}>
        <div className="container">
          <h1>Domsteine Halberstadt</h1>
         {/* <a href="/register" className="btn btn-primary btn-lg">Register</a>
         &nbsp;&nbsp; */}
          <div className="callout callout-info">
            <h4>Benutzung</h4>
            <p>Navigieren Sie auf der folgenden Seite in der 3D Ansicht zum gewünschten Stein und Klicken sie darauf um alle verfügbaren Informationen zu erhalten.</p>
            <p>Nutzen Sie die Suche <i className="fa fa-search" /> um Steine nach bestimmten Kriterien zu selektieren.</p>
          </div>
         <a href="/dashboard" className="btn btn-primary btn-lg">Start</a>
        </div>
      </div>
      )
  }
}
