import React, {Component} from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {Home} from './views/home'
import {Dashboard} from './views/dashboard'
import {Error_404} from './views/Error_404'


class App  extends Component {

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/dashboard' component={Dashboard} />
          <Route path="" component={Error_404} />
        </Switch>
      </Router>
    );
  }

}

export default App;